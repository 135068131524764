import React from "react"

import Index from "../components/index"

const PRODUCTS_ARRAY = {
  FOOD_ARRAY: {
    className: "food-bg",
    products: {
      FOOD: [
        "e9515ee1-e7f7-4036-83c5-f0dd46da8bb2", // Lunch
        "3829c907-6c44-4d56-8389-797cd8b7a833", // Panes
        "709bde06-0309-4267-a42b-cae35ebedf71", // Bar
        "6217fe5f-6ace-476e-a078-4ceb20b342a6", // Tapas
        "afd48400-76eb-4da5-89f6-1284f3de36d1", // Pan Con Focaccia
        "e5955708-c7cf-4282-8206-0b498a32b7ee", // Viking Brod
        "765cecd3-78e6-4324-ab81-c96bca6700e0", // Main
        "a82b7f48-fe81-4ab1-869a-e80ad217791d", // Kids
        "1ebc788d-8837-4c65-923d-0b974dd7da2c", // Dessert
        "5453cf27-81a8-4d31-b4d9-533eb9651ea7", // Bagels By De Broodsmid
        "1296da46-1a79-4807-abf9-bd4581859c55", // Sweets
      ],
    },
    tapasArray: [
      "afd48400-76eb-4da5-89f6-1284f3de36d1", // Pan Con Focaccia
      "e5955708-c7cf-4282-8206-0b498a32b7ee", // Viking Brod
    ],
  },
  DRINKS_ARRAY: {
    className: "drink-bg",
    products: {
      DRINKS: {
        "SOFT & COFFEE": [
          "e2e7b774-e5ae-598b-8a77-cd3c23c5d573", // Soft drinks
          "5747f435-a39f-5279-a5f9-c144d22542df", // Lemonades
          "c5a655e1-2dcf-5774-8ce1-c1ba5337aab1", // Juices
          "b1ee3639-3255-5767-93a1-044e8c0dcec9", // Mocktails
          "1b6b1de3-e9f2-5651-a0b1-df66e3142ab8", // Coffee
          "12928002-8254-5f92-a497-284f056ac836", // Milk
          "efde1254-8a32-528c-b234-c81e2263d300", // Tea
          "3343e1cb-7684-56f0-8d67-ad70d18a61b6", // Taart van de dag
        ],
        BEER: [
          "c47c4a11-e323-5bee-9061-8a0eabdd863a", // Vat
          "3ef993de-dbbd-52fa-b20b-5c8dae8c5eff", // Local Corner
          "d55a97d5-dcd3-5671-b757-5479d8cc5690", // White
          "271d5fb4-bd52-511c-95b8-1ac5e80f4fde", // Sour
          "ee8de9ee-f0fd-5a39-ad39-adafa5822bb1", // Fruits
          "1a99526b-394a-5060-9cc2-674b0a899e55", // Blond
          "8da61056-71d5-5ea6-853c-411d0048ed5a", // Brown
          "74a6d334-a1e1-5248-8b4c-f766e4ba94f3", // Dark & Roasted
          "8c5d3a44-f68a-56b3-9bf5-5f42ac41400f", // Beach, please
          "9f36aea3-f8d9-579c-b793-5ae192679dff", // Bigger better
        ],
        WINE: [
          "78bdef76-3c33-55f8-804e-dc1c2bf6e525", // Wine of the moment
          "f0f4171d-e751-5ae3-a911-4b1b478ec87b", // Fruitig
          "79bede21-f4e3-50bc-9340-265240eac849", // Zoet
          "7277d3bf-4153-5974-b488-841e8671fa60", // Bubbels
          "ba223193-a8be-4bd8-8779-f31fb6cbb152", // Orange Natuurlijk
          "82bc927a-699b-4bcf-9e8c-1548e87d00ee", // Soepel
          "b5fda808-8c32-46c2-b469-f46ac21fce4d", // Natuurlijk
          "1439dc35-acdb-5363-8cf7-4eaf23c9dd2d", // Huis (wit)
          "e9d9272e-5dc7-594e-9051-8dfa83f5928a", // Huis (rosé)
          "2994857c-8381-4ca1-86c5-1a7d1770468f", // Huis (rood)
          "de26c5ef-f662-5d9c-b9b7-d7a4e43b3760", // Vol (wit)
          "d242efed-e4f6-5c92-b1b1-ac4db5b8ead9", // Natuurlijk (wit)
          "ed2a4f56-49f8-4b37-adee-86b3fcab5c10", // Vol (rood)
        ],
      },
      "LIQUOR STORE": {
        COCKTAILS: [
          "8867f814-b41f-44de-aefd-ad8905f83bdd", // Cocktails
          "6aca3096-a2c1-4dc9-86c0-b6b6f99991dd", // Moonshine Lab
        ],
        SPIRITS: [
          "80c59a16-9f1b-4cbc-b87b-ca7ec987e468", // Premium Mixers
          "b9553c7f-0efa-409c-b4ce-9d325616edee", // Gin
          "7479c0aa-be69-4f3f-b7cb-2db7eeddc994", // Rum
          "ee2ce9ab-f8d0-4ecf-8cb9-869f62e56e36", // Tequila, Mezcal & Pisco
          "20441a80-bc74-4355-8c06-19ee2d89d3fc", // Vodka
          "65c2b9b3-9a63-42cb-a3cd-44f4e4ea94fc", // Whiskey
        ],
        BACKROOM: [
          "88adca3f-9e70-410e-84d1-449fc8116ac3", // Classic
          "b311c0df-bbc0-478f-b113-0f2a3a200460", // In The Mix
          "02d5a2a6-8e5c-45b7-895f-dada37358d15", // End
        ],
      },
    },
  },
}

const FoodPage = ({ location }) => (
  <Index productsArray={PRODUCTS_ARRAY} location={location} />
)

export default FoodPage
